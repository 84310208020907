import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Page from '../components/Page'
import Container from '../components/Container'

const Formatter = styled.div`
  margin: 5em 0;

  p {
    font-size: 1.25em;
    line-height: 1.5em;
    margin-bottom: 2em;
  }
`

const GoBack = styled.button`
  border: 0;
  background: 0;
  font-size: 1em;
  letter-spacing: .5px;
  color: #a3aaae;
  margin-bottom: 4em;
  cursor: pointer;

  &:hover {
    color: #104278;
  }
`

const Post = ({ data }) => {
  if (data.markdownRemark) {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark

    return (
      <Page
        title={frontmatter.title}
        info={frontmatter.date}
        background='images/pages/News.jpg'
      >
        <Container>
          <Formatter dangerouslySetInnerHTML={{ __html: html }}></Formatter>
          <Link to='/insights'>
            <GoBack>Back to Insights</GoBack>
          </Link>
        </Container>
      </Page>
    )
  } else {
    return null
  }
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $path } }
    ) {
      html
      frontmatter {
        path
        date
        title
        teaser
      }
    }
  }
`

export default Post
